( function( ) {
	$(document).foundation();

	function initialize() {

		var styles = [
			{
				stylers: [
					{ hue: "#7F7F7F" },
					{ saturation: -200 }
				]
			},
			{
				featureType: "road",
				elementType: "geometry",
				stylers: [
						{ lightness: 200 },
						{ visibility: "simplified" }
					]
			},
			{
				featureType: "road",
				elementType: "labels",
				stylers: [
					{ visibility: "on" }
				]
			}
		];

		// Create the new Mapa amerimed
		var styledMap = new google.maps.StyledMapType(styles,{name: "Mapa Amerimed"});
		var amerimed = new google.maps.LatLng(23.0523876, -109.7021169);

		// Set map options and controls
		var mapOptions = {
			zoom: 13,
			center: amerimed,
			overviewMapControl: true,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.LARGE,
				position: google.maps.ControlPosition.LEFT_TOP
			},
			scrollwheel: false,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.BOTTOM_CENTER,
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
			},
			panControl: true,
			panControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT
			},
			scaleControl: true,
			streetViewControl: true,
			streetViewControlOptions: {
				position: google.maps.ControlPosition.LEFT_TOP
			}
		};
		var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
		var marker = new google.maps.Marker({
			position: amerimed,
			map: map
		});

		map.mapTypes.set('map_style', styledMap);

		var mapOptionsB = {
			zoom: 15,
			scrollwheel: false,
			center: amerimed
		};

		var mapB = new google.maps.Map(document.getElementById('map-canvasB'), mapOptionsB);
		var marker = new google.maps.Marker({
			position: amerimed,
			map: mapB
		});
		}

		function loadScript() {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAf85DJRau_JkUckG0-pqoFxJt3WNNnr4c&sensor=false&language=es&' +
			'callback=initialize';
			document.body.appendChild(script);
		}

		initialize();
}) ( );
